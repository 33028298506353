<template>
  <div
    v-if="
      layout === PROGRAM_GROUPED_BY_DAY_LAYOUT.MOVIES ||
      (layout === PROGRAM_GROUPED_BY_DAY_LAYOUT.SHOWS && shows.length > 0)
    "
    class="grid items-start"
    :class="{
      ' sm:grid-cols-[8rem,1fr]':
        layout === PROGRAM_GROUPED_BY_DAY_LAYOUT.MOVIES,
    }"
  >
    <div
      v-if="!hideDate"
      class="text-primary flex items-baseline space-x-2 text-xl"
      :class="{
        'sm:flex-col': layout === PROGRAM_GROUPED_BY_DAY_LAYOUT.MOVIES,
      }"
    >
      <div
        :class="{
          'sm:text-3xl': layout === PROGRAM_GROUPED_BY_DAY_LAYOUT.SHOWS,
        }"
        v-text="weekDay"
      />
      <div class="sm:text-3xl" v-text="date" />
    </div>

    <div>
      <template v-if="layout === PROGRAM_GROUPED_BY_DAY_LAYOUT.MOVIES">
        <ProgramMovie
          v-for="showGroup in showGroups"
          :key="showGroup.uuid"
          :uuid="showGroup.uuid"
          :movie="showGroup.movie!"
          :link-params="linkParams"
          :show-cinema-names="showCinemaNames"
          :show-playtimes-only="showPlaytimesOnly"
          :show-groups="[showGroup]"
          :flags="showGroup.flags"
          :playtimes-layout="PLAYTIMES_LAYOUT.VERTICAL"
        />
      </template>
      <template v-else>
        <ProgramShowList :shows :link-params="linkParams" />
      </template>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { LinkParam, ShowGroup } from '#gql/default'

interface Props {
  day: string
  hideDate?: boolean
  linkParams?: LinkParam[]
  showGroups: ShowGroup[]
  showCinemaNames?: boolean
  showPlaytimesOnly?: boolean
  layout?: ProgramGroupedByDayLayout
}

const props = defineProps<Props>()

const { d, t } = useI18n()

const weekDay = computed(() => {
  if (isToday(props.day)) {
    return t('label.today')
  } else if (isTomorrow(props.day)) {
    return t('label.tomorrow')
  }

  return d(new Date(props.day), {
    weekday: 'long',
  })
})

const date = computed(() =>
  d(new Date(props.day), {
    day: '2-digit',
    month: '2-digit',
  })
)

const { now } = useServerTime()
const shows = computed(() =>
  props.showGroups
    .flatMap(
      (group) =>
        group.shows?.data.map((show) => ({
          ...show,
          movie: group.movie,
        })) ?? []
    )
    .sort(
      (a, b) =>
        new Date(a.beginning).getTime() - new Date(b.beginning).getTime()
    )
    // Filter out expired shows
    .filter((show) => new Date(show.endsale).getTime() > now.value)
)

defineOptions({
  name: 'ProgramGroupedByDayList',
})
</script>

<i18n>
de:
  label:
    today: "Heute"
    tomorrow: "Morgen"
es:
  label:
    today: "Hoy"
    tomorrow: "Mañana"
</i18n>
