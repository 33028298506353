<template>
  <div
    :class="{
      'dark:divide-neutral-dark-1 divide-neutral-light-1 divide-y':
        layout !== PROGRAM_GROUPED_BY_DAY_LAYOUT.SHOWS,
      'space-y-8': layout === PROGRAM_GROUPED_BY_DAY_LAYOUT.SHOWS,
    }"
  >
    <ProgramMovieSkeleton
      v-for="(_, key) in skeletons"
      :key
      class="pb-6 pt-8"
    />

    <ProgramGroupedByDayListItem
      v-for="{ date: day, showGroups } in groups"
      :key="day"
      :day
      :hide-date
      :show-cinema-names
      :show-playtimes-only
      :show-groups
      :layout="layout ?? PROGRAM_GROUPED_BY_DAY_LAYOUT.MOVIES"
    />
  </div>
</template>

<script lang="ts" setup>
import type { ProgramGroupByDay } from '#gql/default'

interface Props {
  groups?: ProgramGroupByDay[]
  hideDate?: boolean
  renderSkeletons?: number
  showCinemaNames?: boolean
  showPlaytimesOnly?: boolean
  layout?: ProgramGroupedByDayLayout
}

const props = defineProps<Props>()

const skeletons = computed(() =>
  Array.from({ length: props.renderSkeletons ?? 0 })
)

defineOptions({
  name: 'ProgramGroupedByDay',
})
</script>
